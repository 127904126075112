import { Injectable, signal } from '@angular/core';
import { CourseI } from '../courses/interfaces/course.interface';
import { FilterCoursesI } from '../courses/interfaces/filter-courses.interface';
import { FilterStudentsI } from '../students/interfaces/filter-students.interface';
import { StudentI } from '../students/interfaces/student.interface';
import { FilterProgramsI } from './interfaces/filter-programs.interface';
import { ProgramI } from './interfaces/program.interface';
import { PloI } from '../../shared/interfaces/plo.interface';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  constructor() {}

  selectedProgram = signal<ProgramI | null>(null);

  filterPrograms = signal<FilterProgramsI | null>(null);

  editProgram = signal<ProgramI | null>(null);

  addProgram = signal<ProgramI | null>(null);

  deleteProgram = signal<ProgramI | null>(null);

  // ******************************************** ||

  filterCourses = signal<FilterCoursesI | null>(null);

  editCourse = signal<CourseI | null>(null);

  addCourse = signal<CourseI | null>(null);

  // ******************************************** ||

  filterStudents = signal<FilterStudentsI | null>(null);

  editStudent = signal<StudentI | null>(null);

  addStudent = signal<StudentI | null>(null);

  // ******************************************** ||

  editPlo = signal<PloI | null>(null);

  addPlo = signal<PloI | null>(null);

  deletePlo = signal<PloI | null>(null);
}
